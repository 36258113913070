import React from 'react'
import { Row, Col} from 'react-bootstrap';
import 'bootstrap';
import './Team.css';

import Tyler from '../../Images/tyler.png';
import Fred from '../../Images/Fred.png';
import Kait from '../../Images/Kait.png';
import Dylan from '../../Images/Dylan.png';

import Pong from '../../Images/Pong.png';
import Irish from '../../Images/Irish.png';
import jmoyer from '../../Images/JMoyer.png';
import Canter from '../../Images/Canter.png';

import BoostrapCarousel from '../../components/BootstrapCarousel';


export const Team = () => {
  return (
    <div className='container reveal'>
        <Row className='teamRow text-center'>
            <h1 className='col-lg-12 col-centered' style={{ color: "#FFFFFF", fontFamily: 'Exorcist', textAlign: 'center', marginTop:'4rem'}}>
                Duel Realms Team
            </h1>
            <h5 style={{ color: "#FFFFFF", fontFamily: 'Quantico', lineHeight:'34.32px'}}>
                As avid gamers and oldschool TCG collectors we are a group of friends who have always dreamed of moving from the collectible card world to the digital realm.  
                However up until today the digital world was not the ideal medium for a collectible card game due to its inability to provide true ownership and  document provenance.  
                Then came the blockchain and all that changed, we can now assign true asset ownership and usage rights for digital cards as well as implement a tokenized reward system for playing the game.  
                In short the tech has caught up and we are ready to pioneer new grounds in the TCG world. 
            </h5>
        </Row>
        <Row className='cardRow '>
            <Col className='col'>
                <img src={Canter} className='img-fluid'/>
            </Col>
            <Col className='col'>
                <img src={Tyler} className='img-fluid'/>
            </Col>
            <Col className='col'>
                <img src={Dylan} className='img-fluid'/>
            </Col>
            <Col className='col'>
                <img src={Pong} className='img-fluid'/>
            </Col>
        </Row>
        <Row className='cardRow '>
            <Col className='col'>
                <img src={jmoyer} className='img-fluid'/>
            </Col>
            <Col className='col'>
                <img src={Irish} className='img-fluid'/>
            </Col>
            <Col className='col'>
                <img src={Fred} className='img-fluid'/>
            </Col>
            <Col className='col'>
                <img src={Kait} className='img-fluid'/>
            </Col>
        </Row>

        <BoostrapCarousel className='mobileTeam' />
    </div>
  )
}

