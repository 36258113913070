import React from "react";
import "./Footer.css";
  
export const Footer = () => {

  const twitterBtnClick = () => {
    window.open("https://twitter.com/DuelRealms");
}
const discordBtnClick = () => {
  window.open("https://discord.gg/duelrealms");
}

  return (
    <div>
      <div className='duel__footer-content' >
        <div  className="duel__footer-text">© Duel Realms 2022. All Rights Reserved</div>
      </div>
        <div className='duel__footer-socials_container'>
                  <img
                      src={"https://file.rendit.io/n/5SREFNchXOpnT3sEdVnB.svg"}
                      style={{  margin: '0px 7px 0px 0px'}}
                  />
                  <img src={"https://file.rendit.io/n/feYwXt8rqH1Bo5eYaN37.svg"} 
                      style={{  margin: '0px 7px 0px 0px'}}
                      onClick={twitterBtnClick} />
                  <img
                      src={"https://file.rendit.io/n/mI9AfF0w9MAArqp28ChF.svg"} 
                      style={{  margin: '0px 7px 0px 0px'}}
                      onClick={discordBtnClick}
                  />
                  <img
                      src={"https://file.rendit.io/n/jvcdNxVanIj2MIJVuZRP.png"}
                      style={{  margin: '0px 7px 0px 0px'}}
                  />
              </div>
    </div>

  )
}
