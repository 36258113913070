import {Home} from "./pages/Home";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Almanac} from "./pages/Almanac";
import {Marketplace} from "./pages/Marketplace";
import {Nav} from "./pages/Home/Nav";
import './App.css';
import {Footer} from "./components/Footer";
import {ScrollButton} from './components/ScrollButton';
import {MobileNav} from "./pages/Home/MobileNav";

export default function App() {
  
  document.body.style.backgroundColor = '#000000';
  document.body.style.margin = '0';

  return (
    <div className="duel__app-container">
    <Nav />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Almanac" element={<Almanac />} />
        <Route path="/Marketplace" element={<Marketplace />} />
        <Route path="/MobileNav" element={<MobileNav />} />
      </Routes>
    </Router>
    <ScrollButton/>
    <Footer />
    </div>
  );
}
