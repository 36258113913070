import React from 'react';
import {BodyA as Body} from "./Almanac/BodyA";

export const Almanac = ({}) => {
    
  return (
    <>
      <Body />
    </>
  )
}


