import React from 'react';
import "./BodyA.css";
import 'bootstrap';

import wallet from '../../Images/walletMobile.png';
import menu from '../../Images/mobileNav.png';
import {btnhandler} from "../../components/Metamask";

export const BodyA = ({}) => {
    
  return (
    <div className=' container-fluid bg-image2'>
      <div className='mobileNav'>
        <div className='row' style={{border:'none', paddingTop:'3rem', margin:'0'}}>
            <div className='col d-flex justify-content-between customCol'>
                <button  style={{border:'none',  backgroundColor:'transparent'}}>
                  <a href='./MobileNav'  > <img src={menu} width= '56px' height= '52px' style={{border:'3px solid #EA9C1C'}}/>
                  </a>
                            
                </button>
            </div>
        <div className='col d-flex justify-content-between customCol2' >
            <button onClick={btnhandler} style={{border:'none',  backgroundColor:'transparent'}}>
                <img src={wallet} width= '56px' height= '52px' style={{border:'3px solid #EA9C1C'}}/>
              </button>
          </div>
       </div>
      </div>
            <h1 className='h1' style={{ color: "#FFFFFF", fontFamily: 'Quantico', textAlign: 'left', padding: '5rem 0 0 10rem'}}>Coming Soon...</h1>
    </div>
  )
}