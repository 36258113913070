import React from "react";
import {Carousel} from "react-bootstrap";

import jmoyer from "../Images/JMoyer.png";
import Tyler from '../Images/tyler.png';
import Fred from '../Images/Fred.png';
import Kait from '../Images/Kait.png';
import Dylan from '../Images/Dylan.png';
import Pong from '../Images/Pong.png';
import Irish from '../Images/Irish.png';
import Canter from '../Images/Canter.png';



export default function BootstrapCarousel() {

  
  return (
    <div className='mobileTeam'>
      <div class=" testimonial-group">
      <div class="mobilerow text-center">
        <img  src={Canter} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>
        <img src={Tyler} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>
        <img  src={Dylan} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>
        <img  src={Pong} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>

        <img  src={jmoyer} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>
        <img  src={Irish} width= '270px' height= '366px'/>
        <img  src={Fred} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>
        <img  src={Kait} width= '270px' height= '366px' style={{marginRight:'2rem'}}/>


      </div>
    </div>
    </div>
  )
}


