import React from 'react';
import './Header.css';
import styled from "styled-components";
import 'bootstrap';
import {btnhandler} from "../../components/Metamask";
import {Button} from '../../components/Button';
import wallet from '../../Images/walletMobile.png';
import menu from '../../Images/mobileNav.png';

export const Header = () => {


    return (
        <div className='duel__header' id="home">
          <div className='mobileNav'>
                <div className='row' style={{border:'none', paddingTop:'3rem', margin:'0'}}>
                    <div className='col d-flex justify-content-between customCol-1'>
                        <button style={{border:'none', backgroundColor:'transparent'}}>
                            <a href='./MobileNav'  > <img src={menu} width= '56px' height= '52px' style={{border:'3px solid #EA9C1C'}}/>
                            </a>
                        </button>
                    </div>
                    <div className='col d-flex justify-content-between customCol-2' >
                        <button onClick={btnhandler} style={{border:'none', backgroundColor:'transparent'}}>
                            <img src={wallet} width= '56px' height= '52px' style={{border:'3px solid #EA9C1C'}}/>
                        </button>
                    </div>
                </div>
            </div>
            <div className='duel__home-button_container'>
                <Button>Connect Wallet</Button>
            </div>
            <div className='duel__header-content'>
                <p >A WEB3 NFT Trading Card Game</p>
                <h2 >Play to win, hold to earn.</h2>            
                    <h1>What we’re building</h1>
                    <div class="row duel__header-content_container">
                    <div class="col">
                        <h2 >
                            Physical Cards for <br />
                            <p >Holders</p>
                        </h2>
                    </div>
                    <div class="col">
                    <h2 >
                        Blockchain Powered
                        <br />
                        <p >Play & Earn Gaming</p>
                        </h2>
                    </div>
                    <div class="col">
                        <h2  >
                            Official Duel Realms
                            <br />
                            <p >Tournaments</p>
                        </h2>
                    </div>
                    </div>
            </div>
            
        </div>
    )
}

const Icon = styled.img`
  width: 27.47px;
  height: 27.47px;
  margin: 0px 7px 0px 0px;
  cursor: pointer;
`;