import React from 'react';
import {BodyM as Body} from "./Marketplace/BodyM";


export const Marketplace = ({}) => {
    
  return (
    <>
      <Body />
    </>
  )
}