import React from "react";
import {Header} from "./Home/Header";
import {Body} from "./Home/Body";
import {Partners} from "./Home/Partners";
import {Team} from "./Home/Team";
import {Faq} from "./Home/Faq";
import './Home/styles.css';


export const Home = ({}) => {

  return (
    <div className="homeBody">
        <Header />
        <Partners className='reveal'/>
        <Body className='reveal'/>
        <Team className='reveal'/>
        <Faq className='reveal'/>
    </div>
  );
};




