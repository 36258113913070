import React from 'react';
import './Partners.css';
import skyverse from "../../Images/SKYVERSE2.png";
import spaceYetis from "../../Images/SPACEYETIS2.png";
import llama from "../../Images/llamaverse.png";
import cyberkongz from "../../Images/CYBERKONGZ2.png";
import kaiju from "../../Images/KAIJUKINGZ2.png";
import ooga from "../../Images/OOGAVERSE.png";
import pxquest from "../../Images/PXQUEST.png";
import primalBeast from "../../Images/PRIMALBEASTS.png";
import Marquee from 'react-fast-marquee';

import skyverselogo from "../../Images/skyverselogo.png";
import spaceYetislogo from "../../Images/spaceyetilogo.png";
import llamalogo from "../../Images/Llamaverselogo.png";
import cyberkongzlogo from "../../Images/cyberkongzlogo.png";
import kaijulogo from "../../Images/kaijukingzlogo.png";
import oogalogo from "../../Images/oogalogo.png";
import pxquestlogo from "../../Images/pxquestlogo.png";
import primalBeastlogo from "../../Images/primalBeastslogo.png";


export const Partners = () => {
    return (
        <div className='duel__partners reveal'>
            <div className='duel__partnerslogo-content'>
                <Marquee speed={80} gradient={false} direction={"right"} style={{width:'100%',height:'20%'}}>
                    <div className='logos'>
                        <img src={skyverselogo} alt="skyverselogo"/>
                        <img src={spaceYetislogo} alt="spaceYetislogo"/>
                        {/* <img src={cyberkongzlogo} alt="cyberlongzlogo"/> */}
                        <img src={kaijulogo} alt="kaijulogo"/>
                        <img src={oogalogo} alt="oogalogo"/>
                        <img src={pxquestlogo} alt="pxquestlogo"/>
                        {/* <img src={llamalogo} alt="llamalogo"/> */}
                        <img src={primalBeastlogo} alt="primalBeastlogo"/>
                    </div>
                </Marquee>
            </div>
            <div className='duel__partners-content'>
                <Marquee speed={80} gradient={false} >
                    <div className='images'>
                        <img src={spaceYetis} alt="spaceYetis"/>
                        <img src={kaiju} alt="kaiju"/>
                        <img src={pxquest} alt="pxquest"/>
                        <img src={skyverse} alt="skyverse"/>
                        <img src={ooga} alt="ooga"/>
                        {/* <img src={llama} alt="llama"/> */}
                        {/* <img src={cyberkongz} alt="cyberkongz"/> */}
                        <img src={primalBeast} alt="primalBeast"/>
                    </div>
                </Marquee>
                </div>
                <div className='duel__partnerslogo-content'>
                <Marquee speed={80} gradient={false} direction={"right"} >
                    <div className='logos'>
                        <img src={skyverselogo} alt="skyverselogo"/>
                        <img src={spaceYetislogo} alt="spaceYetislogo"/>
                        {/* <img src={cyberkongzlogo} alt="cyberlongzlogo"/> */}
                        <img src={kaijulogo} alt="kaijulogo"/>
                        <img src={oogalogo} alt="oogalogo"/>
                        <img src={pxquestlogo} alt="pxquestlogo"/>
                        {/* <img src={llamalogo} alt="llamalogo"/> */}
                        <img src={primalBeastlogo} alt="primalBeastlogo"/>
                    </div>
                </Marquee>
                </div>
            <div className='duel__partners-content_mobile'>
                    <img src={spaceYetis} alt="spaceYetis"/>
                    <img src={pxquest} alt="pxquest"/>
                    <img src={skyverse} alt="skyverse"/>
            </div>
        </div>
    )
}
