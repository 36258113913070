import React from 'react';
import './Nav.css';
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import etherscan from '../../Images/etherscan.png';

export const Nav = ({}) => {

    const twitterBtnClick = () => {
        window.open("https://twitter.com/DuelRealms");
    }
    const discordBtnClick = () => {
      window.open("https://discord.gg/duelrealms");
  }

  return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-custom">
            <div className="container-fluid">
                    <div className="navbar-nav nav-fill w-100 flex-nowrap flex-row">
                        <a href="./Home" className="nav-item nav-link active link" style={{fontFamily:'Exorcist', color:'white'}}>Home</a>
                        <a href="https://duelrealmsnft.gitbook.io/duel-realms-nft/" target="_blank" rel="noopener noreferrer" className="nav-item nav-link link" style={{fontFamily:'Exorcist', color:'white'}}>Litepaper</a>
                        <a href="https://duelrealmsnft.gitbook.io/duel-realms-nft/gameplay" target="_blank" rel="noopener noreferrer" className="nav-item nav-link link" style={{fontFamily:'Exorcist', color:'white'}}>Rules</a>
                        <a href="./Marketplace" className="nav-item nav-link link" style={{fontFamily:'Exorcist', color:'white'}}>Marketplace</a>
                        <a href="./Almanac" className="nav-item nav-link link" tabindex="-1" style={{fontFamily:'Exorcist', color:'white'}}>Almanac</a>
                        <div className='col text-center flex-nowrap flex-row duel__nav-socials_container' style={{ padding:'0', marginLeft:'2rem', whiteSpace: 'nowrap'}}>
                                <img
                                    src={"https://file.rendit.io/n/5SREFNchXOpnT3sEdVnB.svg"}
                                    style={{  margin: '0px 7px 3px 0px', padding:'0'}}
                                />
                                <img src={"https://file.rendit.io/n/feYwXt8rqH1Bo5eYaN37.svg"} onClick={twitterBtnClick}
                                    style={{  margin: '0px 7px 3px 0px', padding:'0'}} />
                                <img
                                    src={"https://file.rendit.io/n/mI9AfF0w9MAArqp28ChF.svg"} onClick={discordBtnClick}
                                    style={{  margin: '0px 7px 3px 0px', padding:'0'}}
                                />
                                <img
                                    src={etherscan}
                                    width= '29px'
                                    height= '29px'
                                    style={{ padding:'0', margin: '0px 2rem 3px 0px'}}
                                    className='etherscan'
                                />
                        </div>
                    </div>
            </div>
        </nav>


  )
}


