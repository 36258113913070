import React from 'react';
import './Faq.css';
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion, Card, Col } from 'react-bootstrap';


export const Faq = ({}) => {

  const discordBtnClick = () => {
    window.open("https://discord.gg/duelrealms");
}

  return (
    <div className='faq'>
      <div className='container-fluid bg-image reveal'>
            <h1 style={{ color: "#FFFFFF", fontFamily: 'Exorcist', textAlign: 'center', paddingTop: '2rem', fontSize:'64px'}}>FAQ</h1>
              <div className='container' >
                <div className="accordion accordion-flush" id="accordionFlushExample" style={{margin:'6rem 8rem 2rem 8rem'}}>
                <div className="accordion-item col-xs-12 what-tab" style={{ background: 'rgba(255,196,27,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseTwo" style={{color:'white', borderRadius:'5px'}}>
                      What is Duel Realms?
                      <span class="glyphicon glyphicon-minus"></span>
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse font-weight-bold" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Duel Realms is an NFT-based trading card game (TCG) that consists of creature, effect, counter, and wild cards! 
                      Our mission is to create an engaging TCG that can be enjoyed both digitally, through NFTs and our online game, and in real-life through physical trading cards.</div>
                  </div>
                </div>
                <div className="accordion-item col-xs-12 what-tab " style={{ background: 'rgba(255,196,27,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" style={{color:'white', borderRadius:'5px'}}>
                      What is $Duel Token?
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold">The $DUEL token will be ERC-20 token on the Polygon network.
                      The token will be used on our marketplace to purchase physical foil card packs, NFTs, whitelist spots, merchandise, and more. Each NFT will passively earn $DUEL tokens daily! 
                      <br></br>1 $DUEL token = 1 $DUEL token!
                    </div>
                  </div>
                </div>
                <div className="accordion-item col-xs-12 what-tab" style={{ background: 'rgba(255,196,27,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" style={{color:'white', borderRadius:'5px'}}>
                      What is the Duel Realms Game?
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold">We believe that in order for a TCG to achieve long-term success, it must be accessible to everyone, including non-NFT holders! Because of this, the Duel Realms game will be free for anyone to play.
                      This is possible using premade decks built into the game; however, once more cards are released, NFT holders can create and play custom decks if they hold enough cards. 
                      We will also have in-game upgrades available for people who play consistently! </div>
                  </div>
                </div>
                <div className="accordion-item col-xs-12 what-tab " style={{ background: 'rgba(255,196,27,0.5)', border: '2px solid black', color:'white', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" style={{color:'white', borderRadius:'5px'}}>
                      What are the Physical Cards?
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold">Each minting wallet of our Chapter 1: Legend of the Sorcerer NFT collection will receive a FREE 
                          fully holographic starter deck. In addition to this deck, holders of $DUEL token and other confirm partnership collection tokens will have the 
                          ability to purchase Duel Realms foil packs. We have already obtained a longterm partnership with a quality trading card manufacturer that is ready to 
                          produce and distribute our physical cards! Including internationally!</div>
                  </div>
                </div>
                <div className="accordion-item col-xs-8" style={{ background: 'rgba(255,196,27,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive" style={{color:'white', borderRadius:'5px'}}>
                      How Can I Buy a Duel Realms: Legend of the Sorcerer NFT?
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold">The Legend of the Sorcerer NFT Collection consists of 148 different images across 4 different card types and 3 different card editions. Mint information will be announced in our discord!
                    </div>
                  </div>
                </div>
            </div>
              </div>
            <div className='container d-flex justify-content-center'  style={{border:'none'}} >
              <Card className='col-7' style={{background: 'rgba(45, 43, 43, 0.5)', padding: '2rem'}}>
                <div className='text-center'>
                  <button type="button" class="btn btn-lg discordButton" onClick={discordBtnClick} style={{fontSize: 'medium', whiteSpace: 'nowrap', color:'black', fontFamily:'Exorcist'}}>Join Our Discord</button>
                </div>
                <p style={{color: 'white', fontFamily:'Quantico', marginTop:'2rem',fontSize: 'calc(0.23em + 1vw)'}}>
                  We have a special treat for our loyal members! We are happy to announce that we are going to be shipping out 100 physical cards to the most active and engaging members of our discord server. Unlike the red creature cards they will be blue, only 100 copies made AND come with an awesome magnetic case!
                  Winners will earn the <span style={{color: ' #f5ed95'}}>@CHOSEN</span> role!</p>
              </Card>
            </div>
          </div>
    </div>
   
    
  )
}




