import React from 'react';
import { Row, Col, Card} from 'react-bootstrap';
import 'bootstrap';
import './Body.css';
import image1 from "../../Images/CHAPTER_1.png";
import image2 from "../../Images/DUEL_TOKEN.png";
import image3 from "../../Images/TOURNAMENTS.png";
import image4 from "../../Images/PARTNERS_AIRDROP.png";

import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import frame1 from "../../Images/frame1.png";
import frame2 from '../../Images/frame2.png';
import frame3 from '../../Images/frame3.png';
import frame4 from '../../Images/frame4.png';

export const Body = () => {

    const handleDragStart = (e) => e.preventDefault();

    const items = [
    <img src={frame1} width='95%' className='img' onDragStart={handleDragStart} role="presentation" />,
    <img src={frame2} width='95%'  className='img' onDragStart={handleDragStart} role="presentation" />,
    <img src={frame3} width='95%'  className='img' onDragStart={handleDragStart} role="presentation" />,
    <img src={frame4} width='95%'  className='img' onDragStart={handleDragStart} role="presentation" />,
    ];

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);


    return (
        <>
            <div className='mobileBody text-center reveal' >
                <AliceCarousel mouseTracking infinite='true' disableButtonsControls='true' items={items} />
            </div>
            <div className='container hidden' >
                <Row className='row col-xs-8 bgImage reveal' style={{marginTop:'6rem'}}>
                    <Col className='column my-auto bgImage' lg={8}>
                        <h1 style={{ color: "#FFFFFF", fontFamily: 'Exorcist', marginLeft: '2rem' }}>Chapter 1: Legend of the Sorceror</h1>
                        <h5 style={{ color: "#FFFFFF", fontFamily: 'Quantico', marginLeft: '3rem'}}>
                            - 2,000 NFTs including creature, effect, counter, & wild cards. <br></br>
                            - The game will be free for everyone to play & earn, built in unity and integrated with L2 for gasless play.  <br></br>
                            - Genesis cards themselves are L1 ETH.<br></br>
                            - We believe that the best way to build a successful game is to give everyone access to it. NFT holders will get multiple benefits that non holders will not.

                        </h5>
                    </Col>
                    <Col className='col-xs-4' lg={4}>
                        <Card className='card bg-transparent'>
                            <img 
                                width={348}
                                height={349}
                                className="bg-transparent img-fluid mx-auto"
                                src={image1}
                                alt="Chapter 1"  />
                        </Card>
                    </Col>
                </Row>
                <Row className='row col-xs-8 bgImage reveal'>
                    <Col className='column my-auto' lg={8}>
                        <h1 style={{fontSize:"36px", color: "#FFFFFF", fontFamily: 'Exorcist', marginLeft: '2rem'}}>$DUEL Token</h1>
                        <p style={{fontSize:"20px", color: "#FFFFFF", fontFamily: 'Quantico', marginLeft: '3rem'}}>
                                - Earning $DUEL token without staking, players will earn both in sponsered IRL tournaments and online.<br></br>
                                - Our marketplace will run on $DUEL, ETH, & USD to purchase physical foil packs, graded cards, NFTs, Whitelist spots, & Merchandise etc.<br></br>
                                - The fusion chamber will make our NFTs deflationary! 

                        </p>
                    </Col>
                    <Col className='col-xs-4' lg={4}>
                        <Card className='bg-transparent'>
                            <img 
                                width={497}
                                height={508}
                                className="bg-transparent img-fluid mx-auto"
                                src={image2}
                                alt="Duel Token"  />
                        </Card>
                    </Col>
                </Row>
                <Row className='row col-xs-8 bgImage reveal'>
                    <Col className='column my-auto' lg={8}>
                        <h1 style={{fontSize:"36px", color: "#FFFFFF", fontFamily: 'Exorcist', marginLeft: '2rem'}}>Physical Cards & Tournaments</h1>
                        <p style={{fontSize:"20px", color: "#FFFFFF", fontFamily: 'Quantico', marginLeft: '3rem'}}>
                            -Duel Realms will host multiple tournaments a year. Winners from these tournaments will receive physical cards, NFTs, crypto, & other items!<br></br>
                            -Those who mint a NFT from the collector card collections will receive a physical starter deck. The starter deck will consist of 55 holographic cards and come in our custom designed box!
                        </p>
                    </Col>
                    <Col className='col-xs-4' lg={4}>
                        <Card className='bg-transparent'>
                            <img 
                                width={438}
                                height={410}
                                className="bg-transparent img-fluid mx-auto"
                                src={image3}
                                alt="Tournaments"  />
                        </Card>
                    </Col>
                </Row>
                <Row className='row col-xs-8 bgImage reveal'>
                    <Col className='column my-auto' lg={8}>
                        <h1 style={{fontSize:"36px", color: "#FFFFFF", fontFamily: 'Exorcist', marginLeft: '2rem'}}>Partners & Airdrop</h1>
                        <p style={{fontSize:"20px", color: "#FFFFFF", fontFamily: 'Quantico', marginLeft: '3rem'}}>
                            -We have a partnership card collection! Each project we partner with will get their NFT created into a NFT card for our project.<br></br>
                            -A stealth snapshot will take place after mint, unlisted holders at the time of the snapshot will get a PFP airdrop of one of our creatures!<br></br>
                            -Information about diamond partners after mint!
                        </p>
                    </Col>
                    <Col className='col-xs-4' lg={4}>
                        <Card className='bg-transparent'>
                            <img 
                                width={363}
                                height={386}
                                className="bg-transparent img-fluid mx-auto"
                                src={image4}
                                alt="Partners"  />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>

    )
}