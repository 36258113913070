import React from 'react';
import {btnhandler} from "./Metamask";

export const Button = (props) => {

  return (
    <button type="button" class="btn btn-custom btn-lg" onClick={btnhandler}>
      {props.children}
    </button>
  )
}

